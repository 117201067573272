import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const CopyrightContent = styled.div`
  padding: 24px 0;

  @media ${device.tabletL} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`

export const BussinessInfo = styled.span`
  color: #fe3e6d;
`

export const AddressWrapper = styled.span`
  color: #202020;
  display: grid;
  font-size: 1.2rem;
  grid-template-columns: auto;
  line-height: 2rem;
  text-align: center;

  @media ${device.tabletL} {
    text-align: left;
  }

  @media ${device.laptopM} {
    grid-gap: 5px;
    grid-template-columns: auto auto;
  }
`
