import React from 'react'

import * as S from './styled'

interface CopyrightProps {
  className: string
}

const Copyright = ({ className }: CopyrightProps) => (
  <S.CopyrightContent className={className}>
    <S.AddressWrapper>
      <S.BussinessInfo>
        Cora Sociedade de Credito, Financiamento e Investimento S.A. -
        37.880.206/0001-63
      </S.BussinessInfo>
      Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação - São Paulo/SP
    </S.AddressWrapper>
    <S.AddressWrapper>
      <S.BussinessInfo>
        Cora Tecnologia LTDA - 34.052.649/0001-78
      </S.BussinessInfo>
      Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação - São Paulo/SP
    </S.AddressWrapper>
  </S.CopyrightContent>
)

export default Copyright
