import React from 'react'
import { Link } from 'gatsby'

import Menu from '../Menu'
import Button from '../Button'

import growthCookieAB from '../../hooks/growthCookieAB'
import Logo from '../../images/logo-cora.svg'

import * as Styled from './styled'

const Header = ({ toggle }: HeaderProps) => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const isPjAccount = pathname === '/conta-pj/'
  const labelButton = isPjAccount ? 'Abra sua conta' : 'Quero ser Cora'
  const linkStore =
    'https://app.adjust.com/4uz2fax?campaign=conta-pj-digital&engagement_type=fallback_click'

  const handleMainButtonClick = (): void => {
    isPjAccount ? window.open(linkStore, '_blank') : toggle()
  }

  growthCookieAB()

  return (
    <Styled.Header>
      <div className="container">
        <Styled.HeaderContent>
          <Link to="/">
            <img src={Logo} alt="Logo da Cora" />
          </Link>
          <Menu />
          <Styled.LeftContent>
            <Styled.LinkLogin href="https://app.cora.com.br" target="_blank">
              Login
            </Styled.LinkLogin>
            <Button handleClick={handleMainButtonClick} size="small">
              {labelButton}
            </Button>
          </Styled.LeftContent>
        </Styled.HeaderContent>
      </div>
    </Styled.Header>
  )
}

export default Header
