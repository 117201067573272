import React from 'react'

import Copyright from '../Copyright'
import Social from '../Social'
import IconGooglePlay from '../IconGooglePlay'
import IconAppStore from '../IconAppStore'

import * as Styled from './styled'
import SectionInvestors from '../../partials/HomeNew/SectionInvestors'

interface FooterProps {
  storeLink: string
}

const Footer = ({ storeLink }: FooterProps): JSX.Element => (
  <>
    <SectionInvestors />
    <Styled.FooterWrapper>
      <div className="container">
        <Styled.FooterContent>
          <Styled.FooterRow>
            <Styled.ListWrapper>
              <Styled.ListTitle>Cora</Styled.ListTitle>
              <Styled.List>
                <li>
                  <Styled.Link
                    href="https://www.cora.com.br/blog/sobre-a-cora/"
                    target="_blank"
                  >
                    Sobre a Cora
                  </Styled.Link>
                </li>
              </Styled.List>
              <Styled.List>
                <li>
                  <Styled.Link
                    href="https://pj.cora.com.br/sala-de-imprensa/"
                    target="_blank"
                  >
                    Imprensa
                  </Styled.Link>
                </li>
              </Styled.List>
              <Styled.List>
                <li>
                  <Styled.Link
                    href="https://www.cora.com.br/vagas-cora"
                    target="_blank"
                  >
                    Carreiras
                  </Styled.Link>
                </li>
              </Styled.List>
              <Styled.List>
                <li>
                  <Styled.Link
                    href="https://comunidade.cora.com.br/parceiros/"
                    target="_blank"
                  >
                    Contabilidade
                  </Styled.Link>
                </li>
              </Styled.List>
            </Styled.ListWrapper>

            <Styled.ListWrapper>
              <Styled.ListTitle>Produtos</Styled.ListTitle>
              <Styled.List>
                <li>
                  <Styled.Link href="/conta-pj">Conta PJ</Styled.Link>
                </li>
                <li>
                  <Styled.Link href="/cartao-pj">Cartão PJ</Styled.Link>
                </li>
                <li>
                  <Styled.Link href="/conta-pj/gestao-de-cobrancas">
                    Cobranças
                  </Styled.Link>
                </li>
              </Styled.List>
            </Styled.ListWrapper>

            <Styled.ListWrapper>
              <Styled.ListTitle>Recursos</Styled.ListTitle>
              <Styled.List>
                <li>
                  <Styled.Link href="https://www.cora.com.br/blog/">
                    Blog
                  </Styled.Link>
                </li>
                <li>
                  <Styled.Link href="https://www.cora.com.br/blog/ferramentas-cora/">
                    Ferramentas gratuitas
                  </Styled.Link>
                </li>
                <li>
                  <Styled.Link href="https://www.cora.com.br/blog/educacao-financeira/">
                    Educação Financeira
                  </Styled.Link>
                </li>
                <li>
                  <Styled.Link href="https://developers.cora.com.br/">
                    Integrações via API
                  </Styled.Link>
                </li>
              </Styled.List>
            </Styled.ListWrapper>

            <Styled.ListWrapper>
              <Styled.ListTitle>Suporte</Styled.ListTitle>
              <Styled.List>
                <li>
                  <Styled.Link
                    href="http://meajuda.cora.com.br/"
                    target="_blank"
                  >
                    Central de Ajuda
                  </Styled.Link>
                </li>

                <li>
                  <Styled.Link
                    href="https://pj.cora.com.br/seguranca/"
                    target="_blank"
                  >
                    Segurança
                  </Styled.Link>
                </li>
              </Styled.List>
              <br />
              <Styled.ListTitle>Contato</Styled.ListTitle>
              <Styled.List>
                <li>
                  Canais 100% digitais:
                  <br />
                  <Styled.Link href="tel:1150395715">
                    (11) 5039-5715
                  </Styled.Link>
                </li>
                <li>
                  <Styled.Link href="tel:08005912350">
                    0800 591 2350
                  </Styled.Link>
                  <br />
                </li>
                <li>
                  <Styled.Link href="https://mobile.cora.com.br/home/chat">
                    Chat no aplicativo -{' '}
                    <Styled.SmallText>Atendimento 24h</Styled.SmallText>
                  </Styled.Link>
                </li>
                <li>
                  <Styled.Link href="mailto:meajuda@cora.com.br">
                    meajuda@cora.com.br
                  </Styled.Link>
                </li>
              </Styled.List>
            </Styled.ListWrapper>
          </Styled.FooterRow>

          <Styled.FooterRow>
            <Styled.ListWrapper className="store-list">
              <Styled.ListTitle className="store-title">
                Baixe o app
              </Styled.ListTitle>

              <Styled.LinkStoresContainer>
                <IconGooglePlay link={storeLink} negative />

                <IconAppStore link={storeLink} negative />
              </Styled.LinkStoresContainer>
            </Styled.ListWrapper>

            <Styled.ListWrapper className="privacy-list">
              <Styled.ListTitle>Transparência</Styled.ListTitle>

              <Styled.List>
                <li>
                  <Styled.Link href="/regulatorio">Regulatório</Styled.Link>
                </li>
                <li>
                  <Styled.Link href="/ouvidoria">Ouvidoria</Styled.Link>
                </li>
              </Styled.List>
            </Styled.ListWrapper>
          </Styled.FooterRow>
        </Styled.FooterContent>
      </div>

      <Styled.FooterCopyright>
        <div className="container">
          <Styled.FooterCopyrightContent>
            <Copyright className="left-content" />

            <Social className="right-content" />
          </Styled.FooterCopyrightContent>
        </div>
      </Styled.FooterCopyright>
    </Styled.FooterWrapper>
  </>
)

export default Footer
